import {errorPagesConfigs} from './errorPages';
import {authRouteConfig} from './auth';
import {initialUrl} from '../shared/constants/AppConst';
import Error403 from './errorPages/Error403';
import React from 'react';
import Error404 from './errorPages/Error404';
import {cmsConfig} from './cms';

// Roles Management
const RolesManagement = React.lazy(() => import('./roles-management'));
const CreateOrUpdateRole = React.lazy(
  () => import('./roles-management/create-or-update'),
);
const RoleDetails = React.lazy(() => import('./roles-management/role-details'));

// Users Management
const UsersManagement = React.lazy(() => import('./users-management'));
const UserDetails = React.lazy(() => import('./users-management/user-details'));
const CreateOrUpdateUser = React.lazy(
  () => import('./users-management/create-or-update'),
);

// Caregivers
const Caregivers = React.lazy(() => import('./caregivers'));
const CreateOrUpdateCaregiver = React.lazy(
  () => import('./caregivers/create-or-update'),
);

// Clients
const Clients = React.lazy(() => import('./clients'));
const CreateOrUpdateClient = React.lazy(
  () => import('./clients/create-or-update'),
);

// Care Recipients
const CareRecipients = React.lazy(() => import('./care-recipients'));
const CreateOrUpdateCareRecipient = React.lazy(
  () => import('./care-recipients/create-or-update'),
);

// Booking
const Booking = React.lazy(() => import('./booking'));
const CreateOrUpdateBooking = React.lazy(
  () => import('./booking/create-or-update'),
);
const BookinDetails = React.lazy(() => import('./booking/details'));
const BookingManagement = React.lazy(
  () => import('./booking/booking-management'),
);

// Cases
const Cases = React.lazy(() => import('./cases'));
const CreateOrUpdateCase = React.lazy(() => import('./cases/create-or-update'));

// Daily Shifts
const DailyShifts = React.lazy(() => import('./daily-shifts'));

// Caregivers Payrolls
const CaregiversPayrolls = React.lazy(() => import('./caregivers-payrolls'));
const CreateOrUpdateCaregiverPayrolls = React.lazy(
  () => import('./caregivers-payrolls/create-or-update'),
);
const ViewShiftsForCaregiver = React.lazy(
  () => import('./caregivers-payrolls/create-or-update/components/view-shifts'),
);

const authorizedStructure = {
  fallbackPath: '/sign-in',
  unAuthorizedComponent: <Error403 />,
  routes: [
    ...cmsConfig,
    {
      path: '/roles',
      element: <RolesManagement />,
    },
    {
      path: '/roles/create-role',
      element: <CreateOrUpdateRole />,
    },
    {
      path: '/roles/update-role/:id',
      element: <CreateOrUpdateRole />,
    },
    {
      path: '/roles/role-details/:id',
      element: <RoleDetails />,
    },
    {
      path: '/users',
      element: <UsersManagement />,
    },
    {
      path: '/users/user-details/:id',
      element: <UserDetails />,
    },
    {
      path: '/users/create-user',
      element: <CreateOrUpdateUser />,
    },
    {
      path: '/users/update-user/:id',
      element: <CreateOrUpdateUser />,
    },
    {
      path: '/caregivers',
      element: <Caregivers />,
    },
    {
      path: '/caregivers/create',
      element: <CreateOrUpdateCaregiver />,
    },
    {
      path: '/caregivers/update/:id',
      element: <CreateOrUpdateCaregiver />,
    },
    {
      path: '/clients',
      element: <Clients />,
    },
    {
      path: '/clients/create',
      element: <CreateOrUpdateClient />,
    },
    {
      path: '/clients/update/:id',
      element: <CreateOrUpdateClient />,
    },
    {
      path: '/care-recipients',
      element: <CareRecipients />,
    },
    {
      path: '/care-recipients/create',
      element: <CreateOrUpdateCareRecipient />,
    },
    {
      path: '/care-recipients/update/:id',
      element: <CreateOrUpdateCareRecipient />,
    },
    {
      path: '/booking',
      element: <Booking />,
    },
    {
      path: '/booking/update/:id',
      element: <CreateOrUpdateBooking />,
    },
    {
      path: '/booking/details/:id',
      element: <BookinDetails />,
    },
    {
      path: '/booking/management/:id',
      element: <BookingManagement />,
    },
    {
      path: '/cases',
      element: <Cases />,
    },
    {
      path: '/cases/create',
      element: <CreateOrUpdateCase />,
    },
    {
      path: '/cases/update/:id',
      element: <CreateOrUpdateCase />,
    },
    {
      path: '/daily-shifts',
      element: <DailyShifts />,
    },
    {
      path: '/caregivers-payrolls',
      element: <CaregiversPayrolls />,
    },
    {
      path: '/caregivers-payrolls/create',
      element: <CreateOrUpdateCaregiverPayrolls />,
    },
    {
      path: '/caregivers-payrolls/update/:id',
      element: <CreateOrUpdateCaregiverPayrolls />,
    },
    {
      path: '/caregivers-payrolls/view-shifts/:payrollId/:caregiverId',
      element: <ViewShiftsForCaregiver />,
    },
  ],
};

const unAuthorizedStructure = {
  fallbackPath: initialUrl,
  routes: authRouteConfig,
};

const anonymousStructure = {
  routes: errorPagesConfigs.concat([
    {
      path: '*',
      element: <Error404 />,
    },
  ]),
};

export {authorizedStructure, unAuthorizedStructure, anonymousStructure};
