import {createSlice} from '@reduxjs/toolkit';
import {STEPS_NUMBERS} from 'src/shared/constants/AppConst';
import {CaseDetailsDto} from 'src/shared/services/cases/dto/details-case-dto';

interface CasesInitialState {
  caseDetails: CaseDetailsDto | undefined;
  currentTabInCaseDetails: number;
  currentTabInSchedule: number;
  newStartDate: any;
  refreshCaregiverResponses: boolean;
}

const initialState: CasesInitialState = {
  caseDetails: undefined,
  currentTabInCaseDetails: STEPS_NUMBERS.One,
  currentTabInSchedule: STEPS_NUMBERS.One,
  newStartDate: undefined,
  refreshCaregiverResponses: false,
};

const casesSlice = createSlice({
  name: 'cases',
  initialState: initialState,
  reducers: {
    setCaseDetails: (state, action) => {
      state.caseDetails = action.payload;
    },
    setCurrentTabInCaseDetails: (state, action) => {
      state.currentTabInCaseDetails = action.payload;
    },
    setCurrentTabInSchedule: (state, action) => {
      state.currentTabInSchedule = action.payload;
    },
    setNewStartDate: (state, action) => {
      state.newStartDate = action.payload;
    },
    setRefreshCaregiverResponses: (state, action) => {
      state.refreshCaregiverResponses = action.payload;
    },
  },
});

export const {
  setCaseDetails,
  setCurrentTabInCaseDetails,
  setCurrentTabInSchedule,
  setNewStartDate,
  setRefreshCaregiverResponses,
} = casesSlice.actions;

export default casesSlice.reducer;
