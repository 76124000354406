import {createSlice} from '@reduxjs/toolkit';
import {BookingShiftsDto} from 'src/shared/services/booking/dto/booking-shifts-dto';
import {CaregiverPayrollsDetailsDto} from 'src/shared/services/caregivers-payrolls/dto/caregiver-payrolls-details-dto';
import {PayrollsDetailsDto} from 'src/shared/services/caregivers-payrolls/dto/payrolls-details-dto';

interface CaregiversPayrollsInitialState {
  caregiverPayrollsDetails: CaregiverPayrollsDetailsDto | undefined;
  caregiversPayrollsList: Array<CaregiverPayrollsDetailsDto>;
  isCaregiversPayrollsListLoading: boolean;
  caregiversIds: Array<string>;
  filterDateInPayroll: string | undefined;
  totalPayInCreatePayroll: number;
  payrollsDetails: PayrollsDetailsDto | undefined;
  caregiverShiftsList: Array<BookingShiftsDto>;
  isCaregiverShiftsListLoading: boolean;
  openRegisterPaymentModal: boolean;
  newNotificationForUpdatePayroll: any;
}

const initialState: CaregiversPayrollsInitialState = {
  caregiverPayrollsDetails: undefined,
  caregiversPayrollsList: [],
  isCaregiversPayrollsListLoading: false,
  caregiversIds: [],
  filterDateInPayroll: undefined,
  totalPayInCreatePayroll: 0,
  payrollsDetails: undefined,
  caregiverShiftsList: [],
  isCaregiverShiftsListLoading: false,
  openRegisterPaymentModal: false,
  newNotificationForUpdatePayroll: undefined,
};

const caregiverPayrollsSlice = createSlice({
  name: 'caregiverPayrolls',
  initialState: initialState,
  reducers: {
    setCaregiverPayrollsDetails: (state, action) => {
      state.caregiverPayrollsDetails = action.payload;
    },
    setCaregiversPayrollsList: (state, action) => {
      state.caregiversPayrollsList = action.payload;
    },
    setIsCaregiversPayrollsListLoading: (state, action) => {
      state.isCaregiversPayrollsListLoading = action.payload;
    },
    setCaregiversIds: (state, action) => {
      state.caregiversIds = action.payload;
    },
    setFilterDateInPayroll: (state, action) => {
      state.filterDateInPayroll = action.payload;
    },
    setTotalPayInCreatePayroll: (state, action) => {
      state.totalPayInCreatePayroll = action.payload;
    },
    setPayrollsDetails: (state, action) => {
      state.payrollsDetails = action.payload;
    },
    setCaregiverShiftsList: (state, action) => {
      state.caregiverShiftsList = action.payload;
    },
    setIsCaregiverShiftsListLoading: (state, action) => {
      state.isCaregiverShiftsListLoading = action.payload;
    },
    setOpenRegisterPaymentModal: (state, action) => {
      state.openRegisterPaymentModal = action.payload;
    },
    setNewNotificationForUpdatePayroll: (state, action) => {
      state.newNotificationForUpdatePayroll = action.payload;
    },
  },
});

export const {
  setCaregiverPayrollsDetails,
  setCaregiversPayrollsList,
  setIsCaregiversPayrollsListLoading,
  setCaregiversIds,
  setFilterDateInPayroll,
  setTotalPayInCreatePayroll,
  setPayrollsDetails,
  setCaregiverShiftsList,
  setIsCaregiverShiftsListLoading,
  setOpenRegisterPaymentModal,
  setNewNotificationForUpdatePayroll,
} = caregiverPayrollsSlice.actions;

export default caregiverPayrollsSlice.reducer;
